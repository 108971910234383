import {
  takeLatest,
  all,
  call,
  put,
  select,
  takeEvery,
  fork,
} from "redux-saga/effects";

import ModuleApi from "@appsmith/api/ModuleApi";
import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "@appsmith/constants/ReduxActionConstants";
import { validateResponse } from "sagas/ErrorSagas";
import {
  getCurrentModuleId,
  getModuleById,
  getModulePublicAction,
} from "@appsmith/selectors/modulesSelector";
import type { ApiResponse } from "api/ApiResponses";
import type { FetchModuleActionsPayload } from "@appsmith/actions/moduleActions";
import {
  fetchAllModuleEntityCompletion,
  type CreateJSModulePayload,
  type CreateQueryModulePayload,
  type DeleteModulePayload,
  type SaveModuleNamePayload,
  type SetupModulePayload,
  type UpdateModuleInputsPayload,
} from "@appsmith/actions/moduleActions";
import type { ReduxAction } from "@appsmith/constants/ReduxActionConstants";
import type {
  CreateModulePayload,
  FetchModuleActionsResponse,
  RefactorModulePayload,
} from "@appsmith/api/ModuleApi";
import history from "utils/history";
import {
  currentPackageEditorURL,
  moduleEditorURL,
} from "@appsmith/RouteBuilder";
import {
  PluginPackageName,
  type Action,
  type CreateActionDefaultsParams,
  type CreateApiActionDefaultsParams,
} from "entities/Action";
import { createDefaultActionPayloadWithPluginDefaults } from "sagas/ActionSagas";
import type { ModuleMetadata } from "@appsmith/constants/ModuleConstants";
import {
  MODULE_TYPE,
  type Module,
  MODULE_PREFIX,
  MODULE_ENTITY_TYPE,
  MODULE_EDITOR_TYPE,
} from "@appsmith/constants/ModuleConstants";
import type { ModulesReducerState } from "@appsmith/reducers/entityReducers/modulesReducer";
import { getAllModules } from "@appsmith/selectors/modulesSelector";
import { createNewModuleName } from "@appsmith/utils/Packages/moduleHelpers";
import { createDefaultApiActionPayload } from "sagas/ApiPaneSagas";
import { generateDefaultInputSection } from "@appsmith/components/InputsForm/Fields/helper";
import { executePageLoadActions } from "actions/pluginActionActions";
import { createDummyJSCollectionActions } from "utils/JSPaneUtils";
import { getCurrentWorkspaceId } from "@appsmith/selectors/selectedWorkspaceSelectors";
import { generateDefaultJSObject } from "sagas/JSPaneSagas";
import type {
  CreateJSCollectionRequest,
  RefactorAction,
  UpdateCollectionActionNameRequest,
} from "@appsmith/api/JSActionAPI";
import type { JSCollection } from "entities/JSCollection";
import JSActionAPI from "@appsmith/api/JSActionAPI";
import { resetDebuggerLogs } from "sagas/InitSagas";
import {
  UPDATE_MODULE_INPUT_ERROR,
  createMessage,
} from "@appsmith/constants/messages";
import analytics from "@appsmith/utils/Packages/analytics";
import {
  getModulesMetadata,
  getModulesMetadataById,
} from "@appsmith/selectors/packageSelectors";
import type { Diff, DiffEdit } from "deep-diff";
import { take } from "lodash";
import { initialize } from "redux-form";
import {
  API_EDITOR_FORM_NAME,
  QUERY_EDITOR_FORM_NAME,
} from "@appsmith/constants/forms";

function hasInputNameChanged(diff?: Diff<unknown, unknown>[]) {
  if (diff && diff[0].kind === "E") {
    const change = diff[0];
    const key = (change.path || [])?.slice(-1)[0] || "";

    return key === "label";
  }

  return false;
}

function inputNameDiff(diff: DiffEdit<string, string>) {
  return {
    oldName: diff.lhs,
    newName: diff.rhs,
  };
}

function findInputIdFor(inputsForm: Module["inputsForm"], name: string) {
  const input = inputsForm[0].children.find((inp) => inp.label === name);

  return input?.id;
}

export function* deleteModuleSaga(action: ReduxAction<DeleteModulePayload>) {
  try {
    const response: ApiResponse = yield call(
      ModuleApi.deleteModule,
      action.payload,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.DELETE_QUERY_MODULE_SUCCESS,
        payload: action.payload,
      });
      analytics.deleteModule(action.payload.id);

      if (!!action.payload.onSuccess) {
        action.payload.onSuccess();
      } else {
        history.push(currentPackageEditorURL());
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.DELETE_QUERY_MODULE_ERROR,
      payload: { error },
    });
  }
}

export function* saveModuleNameSaga(
  action: ReduxAction<SaveModuleNamePayload>,
) {
  try {
    const { id, name } = action.payload;
    const module: ReturnType<typeof getModuleById> = yield select(
      getModuleById,
      id,
    );
    const currentModuleId: string = yield select(getCurrentModuleId);
    const metadata: ModuleMetadata = yield select(getModulesMetadataById, id);

    if (!module) {
      throw Error("Saving module name failed. Module not found.");
    }

    const refactorPayload: RefactorModulePayload = {
      moduleId: id,
      oldName: module.name,
      newName: name,
    };

    const response: ApiResponse<Module> = yield call(
      ModuleApi.refactorModule,
      refactorPayload,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.SAVE_MODULE_NAME_SUCCESS,
        payload: {
          ...response.data,
          ...metadata,
        },
      });

      // When different module name is modified using the entity explorer
      // calling fetchModuleEntitiesSaga will override current modules's entities in reducer
      if (currentModuleId === id) {
        yield call(fetchModuleEntitiesSaga, {
          payload: { moduleId: id },
          type: ReduxActionTypes.FETCH_MODULE_ENTITIES_INIT,
        });
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.SAVE_MODULE_NAME_ERROR,
      payload: { error },
    });
  }
}

export function* updateModuleInputsSaga(
  action: ReduxAction<UpdateModuleInputsPayload>,
) {
  try {
    const { diff, id, inputsForm, moduleEditorType } = action.payload;
    const module: ReturnType<typeof getModuleById> = yield select(
      getModuleById,
      id,
    );
    const metadata: ModuleMetadata = yield select(getModulesMetadataById, id);

    if (!module) {
      throw Error("Saving module inputs failed. Module not found.");
    }

    let response: ApiResponse<Module>;

    if (diff && hasInputNameChanged(diff)) {
      const changes = inputNameDiff(diff[0] as DiffEdit<string, string>);
      const inputId = findInputIdFor(inputsForm, changes.newName);

      if (!inputId) {
        throw new Error("Could not find input to update name");
      }

      const payload = {
        moduleId: id,
        inputId,
        ...changes,
      };

      response = yield call(ModuleApi.refactorModuleInput, payload);
    } else {
      const payload = {
        ...module,
        inputsForm,
      };

      response = yield call(ModuleApi.updateModule, payload);
    }

    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.UPDATE_MODULE_INPUTS_SUCCESS,
        payload: {
          ...response.data,
          ...metadata,
        },
      });

      analytics.updateModule(response.data);

      if (hasInputNameChanged(diff)) {
        yield call(fetchModuleEntitiesSaga, {
          type: ReduxActionTypes.FETCH_MODULE_ENTITIES_INIT,
          payload: { moduleId: id },
        });

        yield take(ReduxActionTypes.FETCH_MODULE_ENTITIES_SUCCESS);

        const publicAction: Action = yield select(getModulePublicAction, id);

        if (moduleEditorType === MODULE_EDITOR_TYPE.QUERY) {
          yield put(initialize(QUERY_EDITOR_FORM_NAME, publicAction));
        }

        if (moduleEditorType === MODULE_EDITOR_TYPE.API) {
          yield put(initialize(API_EDITOR_FORM_NAME, publicAction));
        }

        /** This is a dummy action to trigger evaluation for the
         * entities fetched after refactoring.
         */
        yield put({
          type: ReduxActionTypes.REFACTOR_MODULE_INPUT_SUCCESS,
          payload: undefined,
        });
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_MODULE_INPUTS_ERROR,
      payload: {
        error: { message: createMessage(UPDATE_MODULE_INPUT_ERROR) },
      },
    });
  }
}

export function* fetchModuleEntitiesSaga(
  action: ReduxAction<FetchModuleActionsPayload>,
) {
  try {
    const response: ApiResponse<FetchModuleActionsResponse> = yield call(
      ModuleApi.getModuleEntities,
      action.payload,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.FETCH_MODULE_ENTITIES_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_MODULE_ENTITIES_ERROR,
      payload: { error },
    });
  }
}

/**
 * Creates an action with specific datasource created by a user
 * @param action
 */
export function* createQueryModuleSaga(
  action: ReduxAction<CreateQueryModulePayload>,
) {
  try {
    const {
      apiType = PluginPackageName.REST_API,
      datasourceId,
      from,
      packageId,
    } = action.payload;
    const allModules: ModulesReducerState = yield select(getAllModules);
    const moduleMetadata: Record<string, ModuleMetadata> =
      yield select(getModulesMetadata);
    const newActionName = createNewModuleName(allModules, MODULE_PREFIX.QUERY);

    const defaultAction: Partial<Action> = datasourceId
      ? yield call(createDefaultActionPayloadWithPluginDefaults, {
          datasourceId,
          from,
          newActionName,
        } as CreateActionDefaultsParams)
      : yield call(createDefaultApiActionPayload, {
          apiType,
          from,
          newActionName,
        } as CreateApiActionDefaultsParams);

    const { name, ...restAction } = defaultAction;
    const payload: CreateModulePayload = {
      packageId,
      name,
      type: MODULE_TYPE.QUERY,
      inputsForm: [generateDefaultInputSection()],
      entity: {
        type: MODULE_ENTITY_TYPE.ACTION,
        ...restAction,
      },
    };

    const response: ApiResponse<Module> = yield call(
      ModuleApi.createModule,
      payload,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.CREATE_QUERY_MODULE_SUCCESS,
        payload: {
          ...response.data,
          ...moduleMetadata[response.data.id],
        },
      });

      analytics.createModule(response.data);

      history.push(moduleEditorURL({ moduleId: response.data.id }));
      yield fork(resetDebuggerLogs);
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.CREATE_QUERY_MODULE_ERROR,
      payload: { error },
    });
  }
}

export function* createJSModuleSaga(
  action: ReduxAction<CreateJSModulePayload>,
) {
  try {
    const { packageId } = action.payload;
    const allModules: ModulesReducerState = yield select(getAllModules);
    const workspaceId: string = yield select(getCurrentWorkspaceId);
    const moduleMetadata: Record<string, ModuleMetadata> =
      yield select(getModulesMetadata);
    const { actions, body, variables } = createDummyJSCollectionActions(
      workspaceId,
      {
        packageId,
      },
    );
    const newModuleName = createNewModuleName(allModules, MODULE_PREFIX.JS);

    const defaultJSObject: CreateJSCollectionRequest =
      yield generateDefaultJSObject({
        name: newModuleName,
        workspaceId,
        actions,
        body,
        variables,
      });

    const payload: CreateModulePayload = {
      packageId,
      name: newModuleName,
      type: MODULE_TYPE.JS,
      entity: {
        type: MODULE_ENTITY_TYPE.JS_OBJECT,
        ...defaultJSObject,
      },
    };

    const response: ApiResponse<Module> = yield call(
      ModuleApi.createModule,
      payload,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.CREATE_JS_MODULE_SUCCESS,
        payload: {
          ...response.data,
          ...moduleMetadata[response.data.id],
        },
      });

      analytics.createModule(response.data);

      history.push(moduleEditorURL({ moduleId: response.data.id }));
      yield fork(resetDebuggerLogs);
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.CREATE_JS_MODULE_ERROR,
      payload: { error },
    });
  }
}

export function* setupModuleSaga(action: ReduxAction<SetupModulePayload>) {
  try {
    const { moduleId } = action.payload;

    yield call(fetchModuleEntitiesSaga, {
      payload: { moduleId: moduleId },
      type: ReduxActionTypes.FETCH_MODULE_ENTITIES_INIT,
    });

    yield put({
      type: ReduxActionTypes.SET_CURRENT_MODULE,
      payload: { id: moduleId },
    });

    // To start eval for new module
    yield put(fetchAllModuleEntityCompletion([executePageLoadActions()]));
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.SETUP_MODULE_ERROR,
      payload: { error },
    });
  }
}

export function* handleRefactorJSActionNameSaga(
  data: ReduxAction<{
    refactorAction: RefactorAction;
    actionCollection: JSCollection;
  }>,
) {
  const { refactorAction } = data.payload;

  if (refactorAction.moduleId) {
    const requestData: UpdateCollectionActionNameRequest = {
      ...data.payload.refactorAction,
      actionCollection: data.payload.actionCollection,
      contextType: "MODULE",
    };
    // call to refactor action
    try {
      const refactorResponse: ApiResponse =
        yield JSActionAPI.updateJSCollectionActionRefactor(requestData);

      const isRefactorSuccessful: boolean =
        yield validateResponse(refactorResponse);

      if (isRefactorSuccessful) {
        yield call(fetchModuleEntitiesSaga, {
          payload: { moduleId: refactorAction.moduleId },
          type: ReduxActionTypes.FETCH_MODULE_ENTITIES_INIT,
        });

        yield put({
          type: ReduxActionTypes.REFACTOR_JS_ACTION_NAME_SUCCESS,
          payload: { collectionId: data.payload.actionCollection.id },
        });
      }
    } catch (error) {
      yield put({
        type: ReduxActionErrorTypes.REFACTOR_JS_ACTION_NAME_ERROR,
        payload: { collectionId: data.payload.actionCollection.id },
      });
    }
  }
}

export default function* modulesSaga() {
  yield all([
    takeLatest(ReduxActionTypes.DELETE_QUERY_MODULE_INIT, deleteModuleSaga),
    takeLatest(ReduxActionTypes.SAVE_MODULE_NAME_INIT, saveModuleNameSaga),
    takeLatest(
      ReduxActionTypes.CREATE_QUERY_MODULE_INIT,
      createQueryModuleSaga,
    ),
    takeLatest(
      ReduxActionTypes.FETCH_MODULE_ENTITIES_INIT,
      fetchModuleEntitiesSaga,
    ),
    takeLatest(ReduxActionTypes.CREATE_JS_MODULE_INIT, createJSModuleSaga),
    takeLatest(
      ReduxActionTypes.UPDATE_MODULE_INPUTS_INIT,
      updateModuleInputsSaga,
    ),
    takeLatest(ReduxActionTypes.SETUP_MODULE_INIT, setupModuleSaga),
    takeEvery(
      ReduxActionTypes.REFACTOR_JS_ACTION_NAME,
      handleRefactorJSActionNameSaga,
    ),
  ]);
}
